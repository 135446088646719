<template>
  <div
    class="u-font-size-xlarge"
  >
    <h3 class="u-mb--m u-mb--l@mobile">
      {{ model.heading }}
    </h3>
    <div class="c-product-tab__nutrition u-flex u-flex-direction-column@mobile">
      <ProductIngredients
        :model="model.ingredients"
      />
      <ProductFacts
        v-if="false"
        :model="model.facts"
      />
    </div>
  </div>
</template>

<script>
import ProductIngredients from '@/CVI/Product/components/ProductIngredients.vue'
import ProductFacts from '@/CVI/Product/components/ProductFacts.vue'

export default {
  components: {
    ProductIngredients,
    ProductFacts
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
