<template>
  <div class="c-main-navigation u-flex">
    <nav
      class="c-main-menu u-hidden-print"
      :class="{
      'is-opened-overlay': isOverlayVisible,
      'has-no-animation': isAnimated,
      'is-opened': isMobileOpened,
      'is-closing': isClosing
    }"
    >
      <button
        class="c-main-menu__button"
        @click="toggleButton"
      >
      <span class="c-main-menu__button-inner">
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
        <span class="c-main-menu__button-line" />
      </span>
      </button>
      <ul
        ref="nav"
        class="c-main-menu__list u-bare-list"
      >
        <li
          v-for="(item, index) in model.menuItems"
          :key="item.title"
          class="c-main-menu__item"
          :class="{
          'is-active-item': index == openIndex
        }"
          @mouseenter="onMenuItemHover(item, index)"
          @mouseleave="onMenuItemLeave(item, index)"
        >
          <a
            class="c-main-menu__item-link"
            :href="item.url"
            :class="{
              'is-active': item.isActive
            }"
            @click="trackNavClick(item.title)"
          >
            {{ item.title }}
          </a>
        </li>
        <li class="c-user-info--mobile u-font-bold u-text-capitalize">{{model.profileMenu.userName}}</li>
        <li class="c-user-info--mobile">
          <a
            class="u-flex u-flex-align-center"
            href="#"
            @click="isContactInfoVisible = true"
          >
            <Icon
              class="u-mr--xs"
              :icon="iconQuestion"
              :size="16"
              stroke="black"
            />
            {{model.profileMenu.infoModal.link}}
          </a>
        </li>
        <li class="c-user-info--mobile">
          <a
            class="u-flex u-flex-align-center"
            :href="model.profileMenu.logOutUrl"
          >
            <Icon
              class="u-mr--xs"
              :icon="iconLogout"
              :size="16"
              stroke="black"
              fill="black"
            />
            {{model.profileMenu.logOutText}}
          </a>
        </li>
      </ul>
    </nav>
    <Modal
      v-model="isContactInfoVisible"
      :title="model.profileMenu.infoModal.title"
      :isTitleCentered="false"
    >
      <div v-html="model.profileMenu.infoModal.content"></div>
    </Modal>
  </div>
</template>

<script>
import tracker from '@/CVI/WebCore/core-tracker'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import iconQuestion from '@ds/svg/icons/stroke/question-circle.svg'
import iconLogout from '@ds/svg/icons/stroke/logout.svg'

export default {
  components: {
    Modal,
    Icon,
    iconQuestion,
    iconLogout
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Icon,
      iconQuestion,
      iconLogout,
      openIndex: -1,
      timer: null,
      isAnimated: false,
      animatedTimer: null,
      isOverlayVisible: false,
      isMobileOpened: false,
      isClosing: false,
      isContactInfoVisible: false,
    }
  },
  methods: {
    trackNavClick(menuItemText) {
      tracker.trackMenuButtonClick({
        event: 'menuButtonClick',
        menuButton: menuItemText
      })
    },
    onMenuItemHover(section, index) {
      if (this.isMobile()) {
        return
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
    },
    onMenuItemLeave() {
      if (this.isMobile()) {
        return
      }
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
      this.timer = setTimeout(() => {
        this.isAnimated = false
        this.openIndex = -1
        this.isOverlayVisible = false
      }, 200)
    },
    toggleButton() {
      if (this.isMobileOpened) {
        this.isClosing = true
        this.isMobileOpened = false
        this.isSubmenuOpened = false
        setTimeout(() => {
          this.isClosing = false
        }, 300)
        tracker.trackClickEvent({ event: 'burgerMenuClose' })
      } else {
        this.isMobileOpened = true
        tracker.trackClickEvent({ event: 'burgerMenuOpen' })
      }
    },
    isMobile() {
      const isTouch = !document.documentElement.classList.contains('no-touch')
      return (isTouch || (this.$mq !== 'desktop' && !isTouch))
    }
  }
}
</script>
