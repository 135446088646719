import Proxy from './components/Proxy'

function toPascalCase(kebabCase) {
  const camelCase = kebabCase.replace(/-([a-z])/g, g => g[1].toUpperCase())
  const pascalCase = camelCase[0].toUpperCase() + camelCase.slice(1)

  return pascalCase
}

function parseProps(dataset) {
  const props = {}

  Object.keys(dataset).forEach((prop) => {
    const val = dataset[prop]

    if (val == 'true' || val == 'True') {
      props[prop] = true
    } else if (val == 'false' || val == 'False') {
      props[prop] = false
    } else if (Number(val)) {
      props[prop] = Number(val)
    } else if (val.length > 1
      && ((val[0] == '[' && val[val.length - 1] == ']')
      || (val[0] == '{' && val[val.length - 1] == '}'))) {
      props[prop] = JSON.parse(val)
    } else {
      props[prop] = val
    }
  })

  return props
}

export default {
  mountComponents(components, store) {
    const vueBlocksArray = Array.from(document.querySelectorAll('[data-vue]'))

    if (vueBlocksArray.length) {
      vueBlocksArray.forEach((el) => {
        const componentName = toPascalCase(el.dataset.vue)
        const component = components[componentName]
        this.mountComponent(el, component, store)
      })
    }
  },
  mountComponent(el, component, store) {
    const props = parseProps(el.dataset)

    new Proxy({
      store,
      propsData: {
        component,
        props
      }
    }).$mount(el)
  }
}
