<template>
  <Accordion
    class="c-product-accordion u-mt--l u-mb--l"
  >
    <AccordionItem
      id="nutrition"
      v-model="activeAccordionItem"
    >
      <template v-slot:title>
        <span class="u-font-size-xlarge u-font-bold">{{ model.nutritionTabTitle }}</span>
      </template>
      <div class="c-tab-content o-content-box">
        <Nutrition
          :model="model.nutrition"
        />
      </div>
    </AccordionItem>
    <AccordionItem
      v-if="model.additionalInformationContent"
      id="additionalInformation"
      v-model="activeAccordionItem"
    >
      <template v-slot:title>
        <span class="u-font-size-xlarge u-font-bold">{{ model.additionalInformationTabTitle }}</span>
      </template>
      <div class="c-tab-content o-content-box">
        <div class="u-font-size-xlarge u-rich-text">
          <h3>{{ model.additionalInformationTabHeading }}</h3>
          <div v-html="model.additionalInformationContent"></div>
        </div>
      </div>
    </AccordionItem>
  </Accordion>
</template>

<script>
import Accordion from '@/CVI/WebCore/components/Accordion.vue'
import AccordionItem from '@/CVI/WebCore/components/AccordionItem.vue'
import Nutrition from '@/CVI/Product/components/Nutrition.vue'

export default {
  components: {
    Accordion,
    AccordionItem,
    Nutrition
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeAccordionItem: ''
    }
  }
}
</script>
