<template>
  <div>
    <h4>{{ model.tableHeading }}</h4>
    <table
      v-if="$mq != 'mobile'"
      class="c-product-tab__nutrition-table u-font-size-large"
    >
      <tr>
        <th class="u-font-size-medium u-text-uppercase">
          {{ model.namesColumnTitle }}
        </th>
        <th class="u-font-size-medium u-text-uppercase">
          {{ model.per100gColumnTitle }}
        </th>
        <th
          v-if="hasPerServing"
          class="u-font-size-medium u-text-uppercase"
        >
          {{ model.perServingColumnTitle }}
        </th>
      </tr>
      <TableRow
        v-for="row in rows"
        :key="row.header"
        :row="row"
        :has-per-serving="hasPerServing"
      />
    </table>


    <table
      v-if="$mq == 'mobile'"
      class="c-product-tab__nutrition-table u-font-size-large"
    >
      <TableRowMobile
        v-for="row in rows"
        :key="row.header"
        :row="row"
        :default-column-title="model.per100gColumnTitle"
        :serving-column-title="model.perServingColumnTitle"
      />
    </table>
  </div>
</template>

<script>
import TableRow from '@/CVI/Product/components/NutritionTableRow.vue'
import TableRowMobile from '@/CVI/Product/components/NutritionTableRowMobile.vue'

export default {
  components: {
    TableRow,
    TableRowMobile
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    rows() {
      const { per100g, perServing } = this.model

      return [
        {
          header: this.model.energyText,
          defaultValue: per100g.energy,
          servingValue: perServing?.energy
        },
        {
          header: this.model.fatText,
          defaultValue: per100g.fat,
          servingValue: perServing?.fat
        },
        {
          header: this.model.saturatedFatText,
          defaultValue: per100g.saturatedFat,
          servingValue: perServing?.saturatedFat,
          subRow: true
        },
        {
          header: this.model.carbohydrateText,
          defaultValue: per100g.carbohydrate,
          servingValue: perServing?.carbohydrate
        },
        {
          header: this.model.sugarsText,
          defaultValue: per100g.sugars,
          servingValue: perServing?.sugars,
          subRow: true
        },
        {
          header: this.model.proteinText,
          defaultValue: per100g.protein,
          servingValue: perServing?.protein
        },
        {
          header: this.model.saltText,
          defaultValue: per100g.salt,
          servingValue: perServing?.salt
        }
      ]
    },
    hasPerServing() {
      return Boolean(this.model.perServing)
    }
  }
}
</script>
