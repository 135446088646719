<template>
  <div>
    <div
      class="o-content-box--side u-flex"
    >
      <Tab
        v-model="activeTab"
        :title="model.nutritionTabTitle"
        href="#nutrition"
        class="u-mr--l"
        @onTabClick="trackTabClick"
      />
      <Tab
        v-if="model.additionalInformationContent"
        v-model="activeTab"
        :title="model.additionalInformationTabTitle"
        href="#additionalInformation"
        class="u-mr--l"
        @onTabClick="trackTabClick"
      />
    </div>
    <div class="c-tab-content o-content-box">
      <TabContent
        v-if="model.additionalInformationContent"
        id="additionalInformation"
        :visible="activeTab == 'additionalInformation'"
      >
        <div class="u-font-size-xlarge u-rich-text">
          <h3>{{ model.additionalInformationTabHeading }}</h3>
          <div v-html="model.additionalInformationContent"></div>
        </div>
      </TabContent>
      <TabContent
        id="nutrition"
        :visible="activeTab == 'nutrition'"
      >
        <Nutrition
          :model="model.nutrition"
        />
      </TabContent>
    </div>
  </div>
</template>

<script>
import Tab from '@/CVI/WebCore/components/Tab.vue'
import TabContent from '@/CVI/WebCore/components/TabContent.vue'
import Nutrition from '@/CVI/Product/components/Nutrition.vue'
import tracker from "@/CVI/WebCore/core-tracker";

export default {
  components: {
    Tab,
    TabContent,
    Nutrition
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'nutrition'
    }
  },
  methods: {
    trackTabClick(tabName) {
      tracker.track({
        event: 'productPageTabClick',
        tabName
      })
    }
  }
}
</script>
