var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-main-navigation u-flex"},[_c('nav',{staticClass:"c-main-menu u-hidden-print",class:{
    'is-opened-overlay': _vm.isOverlayVisible,
    'has-no-animation': _vm.isAnimated,
    'is-opened': _vm.isMobileOpened,
    'is-closing': _vm.isClosing
  }},[_c('button',{staticClass:"c-main-menu__button",on:{"click":_vm.toggleButton}},[_vm._m(0)]),_c('ul',{ref:"nav",staticClass:"c-main-menu__list u-bare-list"},[_vm._l((_vm.model.menuItems),function(item,index){return _c('li',{key:item.title,staticClass:"c-main-menu__item",class:{
        'is-active-item': index == _vm.openIndex
      },on:{"mouseenter":function($event){return _vm.onMenuItemHover(item, index)},"mouseleave":function($event){return _vm.onMenuItemLeave(item, index)}}},[_c('a',{staticClass:"c-main-menu__item-link",class:{
            'is-active': item.isActive
          },attrs:{"href":item.url},on:{"click":function($event){return _vm.trackNavClick(item.title)}}},[_vm._v(" "+_vm._s(item.title)+" ")])])}),_c('li',{staticClass:"c-user-info--mobile u-font-bold u-text-capitalize"},[_vm._v(_vm._s(_vm.model.profileMenu.userName))]),_c('li',{staticClass:"c-user-info--mobile"},[_c('a',{staticClass:"u-flex u-flex-align-center",attrs:{"href":"#"},on:{"click":function($event){_vm.isContactInfoVisible = true}}},[_c('Icon',{staticClass:"u-mr--xs",attrs:{"icon":_vm.iconQuestion,"size":16,"stroke":"black"}}),_vm._v(" "+_vm._s(_vm.model.profileMenu.infoModal.link)+" ")],1)]),_c('li',{staticClass:"c-user-info--mobile"},[_c('a',{staticClass:"u-flex u-flex-align-center",attrs:{"href":_vm.model.profileMenu.logOutUrl}},[_c('Icon',{staticClass:"u-mr--xs",attrs:{"icon":_vm.iconLogout,"size":16,"stroke":"black","fill":"black"}}),_vm._v(" "+_vm._s(_vm.model.profileMenu.logOutText)+" ")],1)])],2)]),_c('Modal',{attrs:{"title":_vm.model.profileMenu.infoModal.title,"isTitleCentered":false},model:{value:(_vm.isContactInfoVisible),callback:function ($$v) {_vm.isContactInfoVisible=$$v},expression:"isContactInfoVisible"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.model.profileMenu.infoModal.content)}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"c-main-menu__button-inner"},[_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"}),_c('span',{staticClass:"c-main-menu__button-line"})])}]

export { render, staticRenderFns }