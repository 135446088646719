<template>
  <div class="service-menu u-flex u-ml--a u-mr--s u-hidden-print">
    <ProfileMenu
      :model="model"
    />
  </div>
</template>

<script>
import ProfileMenu from './ProfileMenu.vue'

export default {
  components: {
    ProfileMenu
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
