import tracker from '@/CVI/WebCore/core-tracker'

const grid = () => {
  const gridContainer = document.querySelectorAll('.c-grid')

  gridContainer.forEach((parent) => {
    const gridItem = parent.querySelectorAll('.c-grid__item')
    const seeAllBtn = parent.querySelectorAll('.js-show-all-grid-items')[0]

    if (seeAllBtn == null) {
      return
    }

    seeAllBtn.addEventListener('click', () => {
      tracker.trackClickEvent({
        event: 'gridSeeMoreClick'
      })
      gridItem.forEach((item) => {
        if (item.classList.contains('hidden')) {
          item.classList.remove('hidden')
        }
      })
      seeAllBtn.classList.add('hidden')
    })
  })
}

export default grid
