<template>
  <div class="c-profile-link">
    <Popover
      v-model="isDropdownVisible"
      classes="c-profile-link__dropdown"
      :placement="'top-start'"
      :container="'.c-profile-link'"
      :auto-close="true"
      :auto-close-delay="20000"
      :offset="2"
    >
      <button
        class="c-service-menu__button"
        :class="{
          'is-opened': isDropdownVisible
        }"
        :title="model.title"
        @click.prevent="openDropdown"
      >
        {{ model.userName }}
        <Icon
          class="u-ml--xs"
          :icon="iconArrow"
          :size="16"
          stroke="black"
        />
      </button>
      <template slot="popover">
        <div class="c-profile-link__list">
          <ul class="u-bare-list">
            <li>
              <a
                class="c-profile-link__list-link u-flex u-flex-align-center"
                href="#"
                @click.prevent="showHelpModal()"
              >
                <Icon
                  class="u-mr--xs"
                  :icon="iconQuestion"
                  :size="16"
                  stroke="black"
                />
                {{ model.infoModal.link }}
              </a>
            </li>
            <li>
              <a
                class="c-profile-link__list-link u-flex u-flex-align-center"
                :href="model.logOutUrl"
                @click="trackLogoutClick()"
              >
                <Icon
                  class="u-mr--xs"
                  :icon="iconLogout"
                  :size="16"
                  stroke="black"
                  fill="black"
                />
                {{ model.logOutText }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </Popover>
    <Modal
      v-model="isContactInfoVisible"
      :title="model.infoModal.title"
      :isTitleCentered="false"
    >
      <div v-html="model.infoModal.content"></div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@/CVI/WebCore/components/Icon.vue'
import iconArrow from '@ds/svg/icons/stroke/arrow-down-1.svg'
import iconQuestion from '@ds/svg/icons/stroke/question-circle.svg'
import iconLogout from '@ds/svg/icons/stroke/logout.svg'
import tracker from '@/CVI/WebCore/core-tracker'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'

export default {
  components: {
    Icon,
    Popover,
    Modal,
    iconArrow,
    iconQuestion,
    iconLogout
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDropdownVisible: false,
      isContactInfoVisible: false,
      iconArrow,
      iconQuestion,
      iconLogout
    }
  },
  methods: {
    openDropdown() {
      this.isDropdownVisible = true
      tracker.trackClickEvent({
        event: 'openProfileMenu'
      })
    },
    showHelpModal() {
      this.isContactInfoVisible = true
      tracker.trackClickEvent({
        event: 'profileNeedHelp'
      })
    },
    trackLogoutClick() {
      tracker.trackClickEvent({
        event: 'profileLogout'
      })
    }
  }
}
</script>
