<template>
  <div class="c-accordion-item">
    <div
      role="button"
      tabindex="0"
      :aria-expanded="value == id"
      class="c-accordion-item__title u-flex u-flex-align-center"
      @click="toggle()"
    >
      <slot name="title" />
      <Icon
        v-if="!isOpened"
        class="u-ml--a"
        :icon="iconOpen"
        :size="16"
        fill="black"
      />
      <Icon
        v-else
        class="u-ml--a"
        :icon="iconClose"
        :size="16"
        fill="black"
      />
    </div>
    <div
      v-show="isOpened"
      class="c-accordion-item__content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import iconOpen from '@ds/svg/icons/bold/add-bold.svg'
import iconClose from '@ds/svg/icons/bold/subtract-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iconOpen,
      iconClose
    }
  },
  computed: {
    isOpened() {
      return this.value == this.id
    }
  },
  methods: {
    toggle() {
      if (this.value == this.id) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.id)
      }
    }
  }
}
</script>
