import './polyfills'
import './icons'

import Vue from 'vue'
import VueMq from 'vue-mq'
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import vueUtil from './vue-util'

// Vanilla scripts
import carousel from '@/CVI/WebCore/components/Carousel'
import grid from '@/CVI/WebCore/components/Grid'

// webcore
import WebCoreMainMenu from '@/CVI/WebCore/components/Navigation/MainMenu.vue'
import WebCoreServiceMenu from '@/CVI/WebCore/components/Navigation/ServiceMenu.vue'

import tracker from '@/CVI/WebCore/core-tracker'

import ProductDetails from '@/CVI/Product/components/ProductDetails.vue'
import ContactInfoLinkButton from '@/CVI/WebCore/components/ContactInfoLinkButton.vue'
import QuestionFormCTA from '@/CVI/WebCore/components/QuestionFormCTA.vue'

Vue.use(Vuelidate)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 992,
    desktop: Infinity
  }
})

Vue.use(VModal, {
  componentName: 'x-modal'
})

const components = {
  // webcore
  WebCoreMainMenu,
  WebCoreServiceMenu,
  ContactInfoLinkButton,
  QuestionFormCTA,

  // Product
  ProductDetails
}

vueUtil.mountComponents(components)

// run vanilla scripts here
carousel()
grid()
tracker.trackLoggedInStatus()
tracker.trackSimpleEventGlobal()
tracker.trackGridPageItemClick()
tracker.trackGridProductItemClick()
tracker.trackBreadcrumbItemClick()
tracker.trackSlideItemClick()
tracker.trackSliderShowMoreClick()

if (navigator.userAgent.match(/Trident\/7\./)) {
    import('@/CVI/WebCore/components/outdated-browser-popup').then((popup) => {
        popup.default()
    })
}
