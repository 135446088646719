import Swiper from 'swiper'

const carousel = () => {
  const slideshow = document.querySelectorAll('.c-carousel__container--wrapper')

  slideshow.forEach((element) => {
    const settings = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 16,
      centerInsufficientSlides: true,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 32
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16
        }
      },
      pagination: {
        el: '.c-carousel-pagination',
        type: 'bullets',
        clickable: true
      }
    }
    if (element.classList.contains('four-column')) {
      settings.breakpoints[769].slidesPerView = 4
    }
    const slider = new Swiper(element, settings)
    return slider
  })
}

export default carousel
