<template>
  <div>
    <a
      href="#"
      class="c-button c-button--dark"
      @click.prevent="showQuestionForm()"
    >
      {{ model.popupButton }}
    </a>
    <Modal
      v-model="isQuestionFormVisible"
      :title="model.title"
      :is-title-centered="false"
      @onClose="trackFormClose"
    >
      <div class="u-mt--l u-mt--m@mobile">
        <form
          ref="questionForm"
          @submit.prevent="sendQuestionForm"
        >
          <div class="u-flex u-flex-direction-column u-mb--m">
            <label
              for="topic"
              class="c-label--m"
            >{{ model.topicTitle }}</label>
            <input
              id="topic"
              v-model="topic"
              name="topic"
              type="text"
              class="c-text-input c-text-input--dirty u-mt--s"
              :placeholder="model.topicPlaceholder"
              required
            >
          </div>
          <div class="u-flex u-flex-direction-column u-mb--s">
            <label
              for="Question"
              class="c-label--m"
            >{{ model.questionTitle }}</label>
            <textarea
              id="question"
              v-model="question"
              name="question"
              class="c-textarea c-text-input--dirty u-mt--s"
              :placeholder="model.questionPlaceholder"
              required
            />
          </div>
          <div class="c-label--s c-label--normal u-mb--m">
            {{ model.terms }}
          </div>
          <div class="u-flex u-mb--m">
            <button
              class="c-button c-button--dark"
              type="submit"
              @click="trackFormSubmit"
            >
              {{ model.submitButton }}
            </button>
            <button
              class="c-button c-button--ghost"
              type="button"
              @click="closeContactForm"
            >
              {{ model.cancelButton }}
            </button>
          </div>
        </form>
      </div>
    </Modal>
    <Modal
      v-model="isSuccessMessageVisible"
      :title="model.successTitle"
      :is-title-centered="false"
      @onClose="trackFormClose"
    >
      <div class="u-mt--s u-mt--m@mobile">
        <p class="u-font-size-medium u-m--0 u-mb--m">
          {{ model.successText }}
        </p>
        <button
          class="c-button c-button--dark"
          @click="isSuccessMessageVisible = false"
        >
          {{ model.successButton }}
        </button>
      </div>
    </Modal>
    <Modal
      v-model="isErrorMessageVisible"
      :title="model.errorTitle"
      :is-title-centered="false"
      @onClose="trackFormClose"
    >
      <div class="u-mt--s u-mt--m@mobile">
        <p class="u-font-size-medium u-m--0 u-mb--m">
          {{ model.errorText }}
        </p>
        <button
          class="c-button c-button--dark"
          @click="isErrorMessageVisible = false; isQuestionFormVisible=true;"
        >
          {{ model.errorButton }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import Modal from './Modal.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {

  components: {
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isQuestionFormVisible: false,
      isSuccessMessageVisible: false,
      isErrorMessageVisible: false,
      topic: '',
      question: ''
    }
  },
  methods: {
    showQuestionForm() {
      this.isQuestionFormVisible = true
      tracker.trackClickEvent({
        event: 'contactFormCta'
      })
    },
    sendQuestionForm() {
      const formData = { topic: this.topic, question: this.question }
      axios
        .post('/api/question/send', formData)
        .then((response) => {
          if (response.status === 200) {
            this.isQuestionFormVisible = false
            this.isErrorMessageVisible = false
            this.isSuccessMessageVisible = true
            tracker.trackClickEvent({
              event: 'contactFormSubmitSuccess'
            })
          }
        })
        .catch((error) => {
          this.isQuestionFormVisible = false
          this.isSuccessMessageVisible = false
          this.isErrorMessageVisible = true
          console.log(error.message)
        })
    },
    closeContactForm() {
      this.isQuestionFormVisible = false
      tracker.trackClickEvent({
        event: 'contactFormCancelClick'
      })
    },
    trackFormSubmit() {
      tracker.trackClickEvent({
        event: 'contactFormSendQuestionClick'
      })
    },
    trackFormClose() {
      tracker.trackClickEvent({
        event: 'contactFormCloseClick'
      })
    }
  }
}
</script>
                                                                                                                                                                                                                                                                                                                                                                                                                                                     