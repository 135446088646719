<template>
  <span>
    <a
      href="#"
      @click.prevent="showContactInfo('loginForgotPass')"
    >
      {{ model.link }}
    </a>
    <Modal
      v-model="isContactInfoVisible"
      :title="model.title"
      :isTitleCentered="false"
    >
      <div v-html="model.content"></div>
    </Modal>
  </span>
</template>

<script>
import Modal from './Modal.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {

  components: {
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isContactInfoVisible: false
    }
  },
  methods: {
    showContactInfo(eventName) {
      this.isContactInfoVisible = true
      tracker.trackClickEvent({
        event: eventName
      })
    }
  }
}
</script>
