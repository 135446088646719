<template>
  <x-modal
    :name="name"
    :duration="1000"
    :adaptive="true"
    :width="640"
    :pivot-y="($mq === 'mobile' || $mq === 'tablet') ? 0.98 : 0.5"
    :max-width="maxWidth"
    height="auto"
    @closed="close"
  >
    <div class="v--modal__wrapper u-flex u-flex-direction-column u-flex-align-center">
      <button
        class="c-button-close--s u-ml--a"
        :class="{
          'u-mb--s': isTitleCentered
        }"
        type="button"
        @click="close"
      >
        <Icon
          :icon="iconClose"
          stroke="black"
          hover-color="medium-gray"
        />
      </button>
      <h3
        class="u-font-size-h3 "
        :class="{
          'u-text-align--center': isTitleCentered,
          'u-text-align--left': !isTitleCentered
        }"
      >
        {{ title }}
      </h3>
      <div class="v--modal__content">
        <slot />
      </div>
    </div>
  </x-modal>
</template>

<script>
import iconClose from '@ds/svg/icons/stroke/close.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    isTitleCentered: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: Math.random().toString(36).slice(2, 5),
      iconClose
    }
  },
  computed: {
    maxWidth() {
      if (this.$mq == 'mobile') {
        return 320
      }

      if (this.$mq == 'tablet') {
        return 480
      }

      return 640
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.$modal.show(this.name)
      } else {
        this.$modal.hide(this.name)
      }
    }
  },

  methods: {
    close() {
      if (this.value) {
        this.$emit('input', false)
        this.$emit('onClose')
      }
    }
  }
}
</script>
