<template>
  <div class="c-product-tab__nutrition-column u-flex-shrink-0">
    <h4>{{ model.ingredientsHeading }}</h4>
    <p v-html="model.ingredients" />
    <div class="u-mb--m" v-html="model.seeAllAllergensText" />
    <h4 v-if="model.allergies">
      {{ model.allergensHeading }}
    </h4>
    <p v-if="model.allergies">
      {{ model.allergensContainsText }} {{ model.allergies }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
