// IE11 polyfills
import 'core-js' // ES6+
import svg4everybody from 'svg4everybody' // external SVG sprites
import 'picturefill' // HTML <picture>
import Stickyfill from 'stickyfilljs' // CSS position:sticky
import objectFitImages from 'object-fit-images' // CSS object-fit/object-position on <img>
import 'intersection-observer'
import smoothscroll from 'smoothscroll-polyfill' // The behavior property of the ScrollToOptions dictionary specifies whether the scrolling should animate smoothly, or happen instantly in a single jump.

svg4everybody()
objectFitImages()
Stickyfill.add(document.querySelectorAll('.js-sticky'))
window.Stickyfill = Stickyfill
setTimeout(() => {
  Stickyfill.refreshAll()
}, 0)
smoothscroll.polyfill()
