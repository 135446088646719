// Create a global tracker object for handling tracking

const gtmData = window.gtmData || {}
const gtmSettings = window.gtmSettings || {}

const Tracker = {
  track(obj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  },

  trackClickEvent({ event }) {
    const clickObject = {
      event
    }
    this.track(clickObject)
  },

  trackMenuButtonClick({ event, menuButton }) {
    const clickObject = {
      event,
      menuButton
    }
    this.track(clickObject)
  },

  trackGridItemClick() {
    const trackingItems = document.querySelectorAll('[data-grid-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'pageGridClick',
          pageGridTitle: item.dataset.trackingTitle
        })
      })
    })
  },

  trackGridPageItemClick() {
    const trackingItems = document.querySelectorAll('[data-grid-page-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'pageGridClick',
          gridTitle: item.dataset.trackingTitle,
          pageTitle: item.dataset.trackingKicker
        })
      })
    })
  },

  trackGridProductItemClick() {
    const trackingItems = document.querySelectorAll('[data-grid-product-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'productGridClick',
          gridTitle: item.dataset.trackingTitle,
          productTitle: item.dataset.trackingKicker
        })
      })
    })
  },

  trackBreadcrumbItemClick() {
    const trackingItems = document.querySelectorAll('[data-breadcrumb-item]')

    trackingItems.forEach((item) => {
      item.addEventListener('click', () => {
        this.track({
          event: 'productPageBreadcrumbClick',
          breadcrumbText: item.dataset.trackingTitle
        })
      })
    })
  },

  trackSlideItemClick() {
    const sliderBlocks = document.querySelectorAll('[data-slider-block]')
    sliderBlocks.forEach((sliderBlock) => {
      const sliderTitleEl = sliderBlock.querySelector('[data-slider-title]')
      const slideItems = sliderBlock.querySelectorAll('[data-slide-item]')
      const { sliderTitle } = sliderTitleEl.dataset

      slideItems.forEach((item) => {
        item.addEventListener('click', () => {
          const { trackingEventName, slideTitleKey, slideTitle } = item.dataset
          const trackingData = {
            event: trackingEventName,
            sliderTitle
          }
          trackingData[slideTitleKey] = slideTitle
          this.track(trackingData)
        })
      })
    })
  },

  trackSliderShowMoreClick() {
    const trackingItems = document.querySelectorAll('[data-slider-more]')

    trackingItems.forEach((item) => {
      const { sliderTitle, trackingEventName } = item.dataset
      item.addEventListener('click', () => {
        this.track({
          event: trackingEventName,
          sliderTitle
        })
      })
    })
  },

  trackSimpleEventGlobal(eventName) {
    const trackingItems = document.querySelectorAll('[data-tracking-event]')

    trackingItems.forEach((item) => {
      const { trackingEvent, trackingTitle } = item.dataset
      if (item.hasAttribute('data-tracking-title')) {
        item.addEventListener('click', () => {
          this.track({
            event: trackingEvent,
            dataTrackingTitle: trackingTitle
          })
        })
      } else {
        if (!eventName) {
          item.addEventListener('click', () => {
            this.track({ event: trackingEvent })
          })
        } else {
          item.addEventListener('click', () => {
            this.track({ event: eventName })
          })
        }
      }
    })
  },

  trackLoggedInStatus() {
    const urlParams = new URLSearchParams(window.location.search)
    const loggedIn = urlParams.get('logged')
    const uri = window.location.toString()
    if (loggedIn) {
      const cleanUri = uri.substring(0, uri.indexOf('?'))
      window.history.replaceState({}, document.title, cleanUri)
      this.track({
        event: 'loginSuccess'
      })
    }
  }
}

window.Tracker = Tracker


export default Tracker
