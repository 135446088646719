<template>
  <v-popover
    :offset="offset"
    :open="value"
    :placement="placement"
    :popover-class="color == 'black' ? 'popover--black': ''"
    trigger="manual"
    :class="classes"
    :container="container"
    @hide="close"
  >
    <slot />

    <template slot="popover">
      <button
        v-if="!autoClose"
        v-close-popover
        class="c-popover__close-button c-button-close--s"
      >
        <Icon
          :icon="iconClose"
          :hover-color="color == 'black' ? 'medium-gray': 'medium-gray'"
          :stroke="color == 'black' ? 'white': 'black'"
        />
      </button>

      <slot name="popover" />
    </template>
  </v-popover>
</template>

<script>
import { VPopover, VClosePopover as ClosePopover } from 'v-tooltip'
import iconClose from '@ds/svg/icons/stroke/close.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  directives: {
    ClosePopover
  },
  components: {
    VPopover,
    Icon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    autoCloseDelay: {
      type: Number,
      default: 1000
    },
    classes: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'white'
    },
    container: {
      type: String,
      default: 'body'
    },
    offset: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      iconClose
    }
  },
  watch: {
    value(isOpened) {
      if (isOpened && this.autoClose) {
        this.timeout = setTimeout(() => {
          // discard if popover is updated to not autoclose
          if (this.autoClose) {
            this.close()
          }
        }, this.autoCloseDelay)
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    }
  }
}
</script>
